import { useIntl } from '@common/i18n';
import Head from 'next/head';

import { NotFound } from '../components';
import { errorsIntlIds } from '../intl';

export default function NotFoundPage() {
  const { formatMessage } = useIntl();

  return (
    <>
      <Head>
        <title>
          {formatMessage({ id: errorsIntlIds.seo.pages.notFound.title })}
        </title>
        <meta
          name="description"
          content={formatMessage({
            id: errorsIntlIds.seo.pages.notFound.description,
          })}
        />
      </Head>
      <NotFound />
    </>
  );
}
