import { useIntl } from '@common/i18n';
import { Button, Flex, Text } from '@common/ui-components';
import { errorsIntlIds } from '@modules/errors/intl';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

export default function NotFound() {
  const { formatMessage } = useIntl();

  const router = useRouter();

  const goBack = () => router?.back();

  return (
    <Flex align="center" justify="center" w="100vw" h="100vh" bg="gray.50">
      <Flex
        direction="column"
        p={8}
        w="28rem"
        gap={8}
        bg="white"
        boxShadow="lg"
      >
        <Text fontWeight="600">
          {formatMessage(
            { id: errorsIntlIds.pageNotFound.title },
            {
              span: (chunks: ReactNode) => (
                <span role="presentation">{chunks}</span>
              ),
            }
          )}
        </Text>

        <Text as="p">
          {formatMessage({ id: errorsIntlIds.pageNotFound.description })}
        </Text>

        <Flex justify="flex-end">
          <Button onClick={goBack}>
            {formatMessage({ id: errorsIntlIds.pageNotFound.button.label })}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
