import { useIntl } from '@common/i18n';
import { Button, Flex, Text } from '@common/ui-components';
import { errorsIntlIds } from '@modules/errors/intl';
import { useRouter } from 'next/router';

import { createHighlightTextWrapper } from '../../../../utils/create-highlight-text-wrapper';

export default function SystemError() {
  const { formatMessage } = useIntl();

  const router = useRouter();

  function handleUserSignOut() {
    router?.replace('/sign-out');
  }

  function handlePageReload() {
    router?.reload();
  }

  return (
    <Flex
      pos="fixed"
      top={0}
      left={0}
      align="center"
      justify="center"
      w="100vw"
      h="100vh"
      bg="gray.50"
    >
      <Flex
        direction="column"
        p={8}
        w="28rem"
        gap={8}
        bg="white"
        boxShadow="lg"
      >
        <Text fontWeight="600">
          {formatMessage({ id: errorsIntlIds.systemError.title })}
        </Text>

        <Text as="p">
          {formatMessage(
            { id: errorsIntlIds.systemError.description },
            { highlight: createHighlightTextWrapper }
          )}
        </Text>

        <Flex gap={3} justify="flex-end">
          <Button
            onClick={handleUserSignOut}
            bg="gray.200"
            color="gray.700"
            _hover={{ bg: 'gray.300' }}
          >
            {formatMessage({
              id: errorsIntlIds.systemError.items.signOut.label,
            })}
          </Button>

          <Button onClick={handlePageReload}>
            {formatMessage({
              id: errorsIntlIds.systemError.items.reload.label,
            })}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
